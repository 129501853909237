import React from 'react';
import { Typography } from '../../components/Typography';
import { ComponentTypes } from '../../types';
import { AppStoreIcon } from '../../assets/icons';

import phoneImageLight from '../../assets/images/phone-full-size-light.webp';
import phoneImageDark from '../../assets/images/phone-full-size-dark.webp';

import styles from './index.module.scss';
import { AppButton } from '../../components/AppButton';
import { useAppContext } from '../../app/providers/useProvider';

export const HeroSection = () => {
  const {
    isDark,
    queryState: { isMobile, isOnlyTablet },
  } = useAppContext();

  return (
    <section className={styles.container}>
      <div data-aos="fade-right" data-aos-once="false" className={styles.boxOuter}>
        <div className={styles.textSection}>
          <Typography className={styles.title} componentType={ComponentTypes.Title} level="h1">
            Craft the Perfect Supplement Routine for Your Body’s Needs.
          </Typography>

          <Typography className={styles.paragraph} componentType={ComponentTypes.Paragraph}>
            Scan, track, and optimize your supplements effortlessly. Avoid conflicts, get personalized dosage
            advice, and discover the best time to take your nutrients.
          </Typography>

          <AppButton className={styles.iconButton}>
            <AppStoreIcon />
          </AppButton>
        </div>
      </div>

      <div data-aos={isMobile || isOnlyTablet ? 'fade-right' : 'fade-left'} data-aos-once="false">
        <img
          src={isDark ? phoneImageDark : phoneImageLight}
          alt="app-presentation"
          className={styles.image}
        />
      </div>
    </section>
  );
};
