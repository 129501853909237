import React, { useMemo } from 'react';
import { Typography } from '../../components/Typography';
import { ComponentTypes } from '../../types';
import { AppButton } from '../../components/AppButton';

import styles from './index.module.scss';

import stepOneDesktopImage from '../../assets/images/step1-light-desktop.png';
import stepTwoDesktopImage from '../../assets/images/step2-light-desktop.png';
import stepThreeDesktopImage from '../../assets/images/step3-light-deskop.png';
import stepFourDesktopImage from '../../assets/images/step4-light-desktop.png';

import stepOneTabletImage from '../../assets/images/step1-light-tablet.png';
import stepTwoTabletImage from '../../assets/images/step2-light-tablet.png';
import stepThreeTabletImage from '../../assets/images/step3-light-tablet.png';
import stepFourTabletImage from '../../assets/images/step4-light-tablet.png';

import stepOneMobileImage from '../../assets/images/step1-light-mobile.png';
import stepTwoMobileImage from '../../assets/images/step2-light-mobile.png';
import stepThreeMobileImage from '../../assets/images/step3-light-mobile.png';
import stepFourMobileImage from '../../assets/images/step4-light-mobile.png';

import stepOneDesktopImageDark from '../../assets/images/step1-dark-desktop.png';
import stepTwoDesktopImageDark from '../../assets/images/step2-dark-desktop.png';
import stepThreeDesktopImageDark from '../../assets/images/step3-dark-desktop.png';
import stepFourDesktopImageDark from '../../assets/images/step4-dark-desktop.png';

import layoutTopLeft from '../../assets/images/layout-top-left-min.png';
import layoutMidRight from '../../assets/images/layout-mid-right-min.png';

import cn from 'classnames';
import { ButtonVariant } from '../../app/types';
import { ProgressBar } from './components/ProgressBar';
import { useAppContext } from '../../app/providers/useProvider';
import { AnimatedLayout } from '../../components/AnimatedLayout';

type ImageSet = {
  imageOne: string;
  imageTwo: string;
  imageThree: string;
  imageFour: string;
};

export const HowItWorksSection = () => {
  const {
    queryState: { isOnlyTablet, isMobile, isDesktop },
    isDark,
  } = useAppContext();

  const images = useMemo<ImageSet>(() => {
    const getImages = (desktopImages: ImageSet, tabletImages: ImageSet, mobileImages: ImageSet): ImageSet => {
      if (isDesktop) return desktopImages;
      if (isOnlyTablet) return tabletImages;
      if (isMobile) return mobileImages;
      return {
        imageOne: '',
        imageTwo: '',
        imageThree: '',
        imageFour: '',
      };
    };

    if (isDark) {
      return getImages(
        {
          imageOne: stepOneDesktopImageDark,
          imageTwo: stepTwoDesktopImageDark,
          imageThree: stepThreeDesktopImageDark,
          imageFour: stepFourDesktopImageDark,
        },
        {
          imageOne: stepOneDesktopImageDark,
          imageTwo: stepTwoDesktopImageDark,
          imageThree: stepThreeDesktopImageDark,
          imageFour: stepFourDesktopImageDark,
        },
        {
          imageOne: stepOneDesktopImageDark,
          imageTwo: stepTwoDesktopImageDark,
          imageThree: stepThreeDesktopImageDark,
          imageFour: stepFourDesktopImageDark,
        },
      );
    }

    return getImages(
      {
        imageOne: stepOneDesktopImage,
        imageTwo: stepTwoDesktopImage,
        imageThree: stepThreeDesktopImage,
        imageFour: stepFourDesktopImage,
      },
      {
        imageOne: stepOneTabletImage,
        imageTwo: stepTwoTabletImage,
        imageThree: stepThreeTabletImage,
        imageFour: stepFourTabletImage,
      },
      {
        imageOne: stepOneMobileImage,
        imageTwo: stepTwoMobileImage,
        imageThree: stepThreeMobileImage,
        imageFour: stepFourMobileImage,
      },
    );
  }, [isDark, isDesktop, isMobile, isOnlyTablet]);

  return (
    <section className={styles.container} id="How it works">
      <Typography
        dataAos="fade-down"
        className={styles.title}
        componentType={ComponentTypes.Title}
        level="h2"
      >
        How it works
      </Typography>

      <Typography dataAos="fade-up" className={styles.subTitle} componentType={ComponentTypes.Paragraph}>
        Our app simplifies your health and wellness journey by providing personalized supplement guidance at
        your fingertips.
      </Typography>

      {isMobile ? (
        <>
          <div className={styles.appDemonstrationContainerMobile}>
            <ProgressBar />

            <div data-aos="fade-left" className={styles.appDemonstrationSectionMobile}>
              <Typography
                className={styles.appDemonstrationTitle}
                componentType={ComponentTypes.Title}
                level="h3"
              >
                Get MyVitaAI App
              </Typography>
              <Typography
                className={cn(styles.appDemonstrationSubTitle, styles.appAppDemonstrationSubTitleMobile)}
                componentType={ComponentTypes.Paragraph}
              >
                Download from the
                <AppButton className={styles.appStoreButton}>
                  <span className={styles.appStoreText}>App Store</span>
                </AppButton>
                to begin your journey.
              </Typography>

              <img className={styles.image} src={images.imageOne} alt="get-myvitaAi-app" />
            </div>

            <div data-aos="fade-left" className={styles.appDemonstrationSectionMobile}>
              <Typography
                className={styles.appDemonstrationTitle}
                componentType={ComponentTypes.Title}
                level="h3"
              >
                Create an Account
              </Typography>

              <Typography
                className={cn(styles.appDemonstrationSubTitle, styles.appAppDemonstrationSubTitleMobile)}
                componentType={ComponentTypes.Paragraph}
              >
                Quickly set up your profile to personalize your experience.
              </Typography>

              <img className={styles.image} src={images.imageTwo} alt="create-account-myvitaAi-app" />
            </div>

            <div data-aos="fade-left" className={styles.appDemonstrationSectionMobile}>
              <Typography
                className={styles.appDemonstrationTitle}
                componentType={ComponentTypes.Title}
                level="h3"
              >
                Subscribe to a Plan
              </Typography>

              <Typography
                className={cn(styles.appDemonstrationSubTitle, styles.appAppDemonstrationSubTitleMobile)}
                componentType={ComponentTypes.Paragraph}
              >
                Select a plan that fits your health goals and budget.
              </Typography>

              <img className={styles.image} src={images.imageThree} alt="subscribe-plan-myvitaAi-app" />
            </div>

            <div data-aos="fade-left" className={styles.appDemonstrationSectionMobile}>
              <Typography
                className={styles.appDemonstrationTitle}
                componentType={ComponentTypes.Title}
                level="h3"
              >
                Enjoy All the Features
              </Typography>
              <Typography
                className={cn(styles.appDemonstrationSubTitle, styles.appAppDemonstrationSubTitleMobile)}
                componentType={ComponentTypes.Paragraph}
              >
                Access all tools to optimize your supplement routine effortlessly.
              </Typography>

              <img className={styles.image} src={images.imageFour} alt="enjoy-the-feature-myvitaAi-app" />
            </div>
          </div>
          <div data-aos="fade-right">
            <AppButton
              className={styles.startButton}
              hoverColor="#F7B300"
              activeColor="#F5A700"
              color="#FDCC1B"
              variant={ButtonVariant.PRIMARY}
            >
              <Typography className={styles.buttonText} componentType={ComponentTypes.Link}>
                Start now
              </Typography>
            </AppButton>
          </div>
        </>
      ) : (
        <div className={styles.appDemonstrationContainer}>
          {isDesktop && (
            <>
              <AnimatedLayout
                image={layoutTopLeft}
                style={{
                  left: -500,
                  height: 600,
                  top: -190,
                  transform: 'scale(1.3)',
                }}
              />

              <AnimatedLayout
                image={layoutMidRight}
                style={{
                  right: -440,
                  top: 800,
                  height: 800,
                }}
              />
            </>
          )}

          <ProgressBar />
          <div className={styles.appDemonstrationSection}>
            <div data-aos="fade-right" className={styles.innerLeftSection}>
              <div className={styles.textBlock}>
                <Typography
                  className={styles.appDemonstrationTitle}
                  componentType={ComponentTypes.Title}
                  level="h3"
                >
                  Get MyVitaAI App
                </Typography>
                <Typography
                  className={cn(styles.appDemonstrationSubTitle, styles.subTitleRight)}
                  componentType={ComponentTypes.Paragraph}
                >
                  Download from the
                  <AppButton className={styles.appStoreButton}>
                    <span className={styles.appStoreText}>App Store</span>
                  </AppButton>
                  to begin your journey.
                </Typography>
              </div>
            </div>

            <div data-aos="fade-left" className={styles.innerRightSection}>
              <img className={styles.image} src={images.imageOne} alt="get-myvitaAi-app" />
            </div>
          </div>

          <div className={styles.appDemonstrationSection}>
            <div data-aos="fade-right" className={styles.innerLeftSection}>
              <img className={styles.image} src={images.imageTwo} alt="create-account-myvitaAi-app" />
            </div>

            <div data-aos="fade-left" className={styles.innerRightSection}>
              <div className={styles.textBlock}>
                <Typography
                  className={styles.appDemonstrationTitle}
                  componentType={ComponentTypes.Title}
                  level="h3"
                >
                  Create an Account
                </Typography>
                <Typography
                  className={cn(styles.appDemonstrationSubTitle, styles.subTitleLeft)}
                  componentType={ComponentTypes.Paragraph}
                >
                  Quickly set up your profile to personalize your experience.
                </Typography>
              </div>
            </div>
          </div>

          <div className={styles.appDemonstrationSection} id="Pricing?">
            <div data-aos="fade-right" className={styles.innerLeftSection}>
              <div className={styles.textBlock}>
                <Typography
                  className={styles.appDemonstrationTitle}
                  componentType={ComponentTypes.Title}
                  level="h3"
                >
                  Subscribe to a Plan
                </Typography>

                <Typography
                  className={cn(styles.appDemonstrationSubTitle, styles.subTitleRight)}
                  componentType={ComponentTypes.Paragraph}
                >
                  Select a plan that fits your health goals and budget.
                </Typography>
              </div>
            </div>

            <div data-aos="fade-left" className={styles.innerRightSection}>
              <img className={styles.image} src={images.imageThree} alt="subscribe-plan-myvitaAi-app" />
            </div>
          </div>

          <div className={styles.appDemonstrationSection} id="Get access">
            <div data-aos="fade-right" className={styles.innerLeftSection}>
              <img className={styles.image} src={images.imageFour} alt="enjoy-the-feature-myvitaAi-app" />
            </div>

            <div data-aos="fade-left" className={styles.innerRightSection}>
              <div className={styles.textBlock}>
                <Typography
                  className={styles.appDemonstrationTitle}
                  componentType={ComponentTypes.Title}
                  level="h3"
                >
                  Enjoy All the Features
                </Typography>
                <Typography
                  className={cn(styles.appDemonstrationSubTitle, styles.subTitleLeft)}
                  componentType={ComponentTypes.Paragraph}
                >
                  Access all tools to optimize your supplement routine effortlessly.
                </Typography>

                <AppButton
                  className={styles.startButton}
                  hoverColor="#F7B300"
                  activeColor="#F5A700"
                  color="#FDCC1B"
                  variant={ButtonVariant.PRIMARY}
                >
                  <Typography className={styles.buttonText} componentType={ComponentTypes.Link}>
                    Start now
                  </Typography>
                </AppButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};
