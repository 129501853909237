import { Navigation } from '../../modules/Navigation';

import { HeroSection } from '../../modules/HeroSection';
import { FeaturesSection } from '../../modules/FeaturesSection';
import { HowItWorksSection } from '../../modules/HowItWorksSection';
import { Footer } from '../../modules/Footer';
import { MainWrapper } from '../../components/MainWrapper';

export const Main = () => {
  return (
    <MainWrapper>
      <Navigation />
      <HeroSection />
      <FeaturesSection />
      <HowItWorksSection />
      <Footer />
    </MainWrapper>
  );
};
