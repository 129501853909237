import { FC } from 'react';
import { Typography } from '../../components/Typography';
import { ComponentTypes } from '../../types';
import { NAVIGATION_TITLES } from '../../lib/constants';
import { AppButton } from '../../components/AppButton';

import {
  BurgerMenuIcon,
  InstLightIcon,
  InstDarkIcon,
  XDarkIcon,
  XLightIcon,
  LogoLightIcon,
  LogoDarkIcon,
} from '../../assets/icons';

import styles from './index.module.scss';
import { ButtonVariant } from '../../app/types';
import { useAppContext } from '../../app/providers/useProvider';
import { DarkModeToggle } from '../../components/DarkModeToggle';

export const Navigation: FC = () => {
  const {
    queryState: { isOnlyTablet, isMobile, isDesktop },
    isDark,
  } = useAppContext();

  return (
    <header className={styles.container} id="home">
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          {(isMobile || isOnlyTablet) && (
            <AppButton className={styles.burgerButton}>
              <BurgerMenuIcon fill={isDark ? '#ffffff' : '#111827'} />
            </AppButton>
          )}

          {isDark ? <LogoLightIcon /> : <LogoDarkIcon />}
        </div>

        {isDesktop && (
          <nav className={styles.nav}>
            <ul className={styles.navList}>
              {NAVIGATION_TITLES.map((navTitle) => (
                <Typography
                  key={navTitle}
                  className={styles.navListItemName}
                  componentType={ComponentTypes.Link}
                  href={`#${navTitle}`}
                >
                  {navTitle}
                </Typography>
              ))}
            </ul>
          </nav>
        )}

        <div className={styles.buttonsContainer}>
          <div className={styles.iconsContainer}>
            <DarkModeToggle />

            <AppButton className={styles.iconButton}>
              {isDark ? <InstDarkIcon /> : <InstLightIcon />}
            </AppButton>

            <AppButton className={styles.iconButton}>{isDark ? <XDarkIcon /> : <XLightIcon />}</AppButton>
          </div>

          {(isDesktop || isOnlyTablet) && (
            <AppButton
              className={styles.downloadButton}
              hoverColor="#F7B300"
              activeColor="#F5A700"
              color="#FDCC1B"
              variant={ButtonVariant.PRIMARY}
            >
              <Typography className={styles.downloadButtonText} componentType={ComponentTypes.Link}>
                Download
              </Typography>
            </AppButton>
          )}
        </div>
      </div>
    </header>
  );
};
