import { ScanIcon, SettingsIcon, ChoiceIcon } from '../../assets/icons';

export const NAVIGATION_TITLES = ['Features', 'How it works', 'Pricing?', 'Get access'];

export const FEATURE_LIST = [
  {
    icon: ScanIcon,
    title: 'Scanning Supplements',
    description: 'Get immediate insights into potential interactions, proper dosages, and optimal timing.',
  },
  {
    icon: SettingsIcon,
    title: 'Managing Intakes',
    description:
      'Easily manage your daily intake of vitamins, minerals, and supplements for a balanced health regimen.',
  },
  {
    icon: ChoiceIcon,
    title: 'Choosing Alternatives',
    description:
      'Find safe and effective alternatives tailored to your needs, ensuring your health goals are always met.',
  },
];

export const CONTAINER_QUERIES = {
  isMobile: {
    maxWidth: 743,
  },
  isOnlyTablet: {
    minWidth: 744,
    maxWidth: 1200,
  },
  isDesktop: {
    minWidth: 1201,
  },
};
