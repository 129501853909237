import React from 'react';
import { Typography } from '../../components/Typography';
import { ComponentTypes } from '../../types';

import styles from './index.module.scss';
import { AppStoreIcon } from '../../assets/icons';
import { AppButton } from '../../components/AppButton';
import { ButtonVariant } from '../../app/types';

export const Footer = () => {
  return (
    <footer className={styles.footerWrapper}>
      <div className={styles.top}>
        <div>
          <Typography className={styles.title} componentType={ComponentTypes.Title}>
            Stay up to date
          </Typography>
          <div className={styles.inputWrapper}>
            <AppButton
              className={styles.button}
              hoverColor="#F7B300"
              activeColor="#F5A700"
              color="#FDCC1B"
              variant={ButtonVariant.PRIMARY}
            >
              Submit
            </AppButton>
            <input className={styles.input} type="text" placeholder="Your email " />
          </div>
        </div>

        <AppButton>
          <AppStoreIcon />
        </AppButton>
      </div>

      <div className={styles.bottom}>
        <Typography className={styles.text} componentType={ComponentTypes.Title}>
          info@myvita.ai
        </Typography>

        <div className={styles.row}>
          <Typography className={styles.text} componentType={ComponentTypes.Title}>
            MyVitaAI © 2024
          </Typography>
          <div className={styles.round} />
          <Typography href="privacy" className={styles.text} componentType={ComponentTypes.Link}>
            Privacy Policy
          </Typography>
          <div className={styles.round} />
          <Typography className={styles.text} componentType={ComponentTypes.Link}>
            Terms of Use
          </Typography>
        </div>

        <Typography className={styles.text} componentType={ComponentTypes.Paragraph}>
          Protected by a U.S utility patent & other patent pending applications.
        </Typography>
      </div>
    </footer>
  );
};
