export enum ROUTER_PATHS {
  home = '/',
  noMatch = '*',
  privacy = 'privacy',
}

export enum ButtonVariant {
  TEXT = 'TEXT',
  PRIMARY = 'PRIMARY',
}

export type ContainerQuerySliceT = {
  isMobile: boolean;
  isOnlyTablet: boolean;
  isDesktop: boolean;
};

export const initialState: ContainerQuerySliceT = {
  isMobile: false,
  isOnlyTablet: false,
  isDesktop: false,
};
