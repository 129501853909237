import { Navigation } from '../../modules/Navigation';
import { MainWrapper } from '../../components/MainWrapper';
import { PrivacyPolicy } from '../../modules/PrivacyPolicy';

export const Privacy = () => {
  return (
    <MainWrapper>
      <Navigation />
      <PrivacyPolicy />
    </MainWrapper>
  );
};
