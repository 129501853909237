import React, { useEffect, useMemo, useRef, useState } from 'react';

import styles from './index.module.scss';

import cn from 'classnames';
import { useAppContext } from '../../../../app/providers/useProvider';

const deviceHeight = window.innerHeight;
const mobileSmallHeightMin = 667;
const mobileSmallHeightMax = 740;

const mobileLargeHeightMin = 741;
const mobileLargeHeightMax = 960;

const getMobilePositionCoefficient = () => {
  if (deviceHeight <= mobileLargeHeightMax && deviceHeight >= mobileLargeHeightMin) {
    return 2.4;
  } else if (deviceHeight >= mobileSmallHeightMin && deviceHeight <= mobileSmallHeightMax) {
    return 3.1;
  }

  return 2;
};

const positionCoefficients = {
  desktop: 1.6,
  tablet: 2.2,
  mobile: getMobilePositionCoefficient(),
};

export const ProgressBar = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const {
    queryState: { isOnlyTablet, isMobile, isDesktop },
  } = useAppContext();

  const coefficientRef = useRef<number | null>(null);

  const startPositionCoefficient = useMemo(() => {
    if (isDesktop) {
      return positionCoefficients.desktop;
    }

    if (isOnlyTablet) {
      return positionCoefficients.tablet;
    }

    if (isMobile) {
      return positionCoefficients.mobile;
    }

    return 1.5;
  }, [isDesktop, isMobile, isOnlyTablet]);

  const pointsPosition = useMemo(() => {
    if (isMobile) {
      return {
        firstPoint: 45,
        secondPoint: 505,
        thirdPoint: 975,
        fourPoint: 1440,
      };
    }

    return {
      firstPoint: 80,
      secondPoint: 575,
      thirdPoint: 1075,
      fourPoint: 1575,
    };
  }, [isMobile]);

  const breakpoints = isMobile
    ? {
        value1: 4.6,
        value2: 26.8,
        value3: 52.1,
        value4: 79,
      }
    : {
        value1: 4.6,
        value2: 28.79,
        value3: 54,
        value4: 77.5,
      };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    const docHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;

    if (coefficientRef.current) {
      const progressStartPosition = document.documentElement.clientHeight * coefficientRef.current;

      const scrollPercent = ((scrollTop - progressStartPosition) / (docHeight - progressStartPosition)) * 100;

      const normalizedScrollPercent = Math.max(1, Math.min(scrollPercent, 100));

      setScrollPosition(normalizedScrollPercent);
    }
  };

  useEffect(() => {
    coefficientRef.current = startPositionCoefficient;
  }, [startPositionCoefficient]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={styles.progressContainer}>
      <div className={styles.track}></div>

      <div className={styles.trackActive} style={{ height: `${scrollPosition}%` }} />

      <div className={styles.trackEnding}>
        <div className={styles.trackEndingInner} />
      </div>

      <div style={{ top: pointsPosition.firstPoint }} className={styles.thumbWrapper}>
        <div className={cn(styles.thumb, scrollPosition >= breakpoints.value1 && styles.thumbActive)}>
          <div
            className={cn(
              styles.thumbInnerCircle,
              scrollPosition >= breakpoints.value1 && styles.thumbInnerActive,
            )}
          />
        </div>
      </div>

      <div style={{ top: pointsPosition.secondPoint }} className={styles.thumbWrapper}>
        <div className={cn(styles.thumb, scrollPosition >= breakpoints.value2 && styles.thumbActive)}>
          <div
            className={cn(
              styles.thumbInnerCircle,
              scrollPosition >= breakpoints.value2 && styles.thumbInnerActive,
            )}
          />
        </div>
      </div>
      <div style={{ top: pointsPosition.thirdPoint }} className={styles.thumbWrapper}>
        <div className={cn(styles.thumb, scrollPosition >= breakpoints.value3 && styles.thumbActive)}>
          <div
            className={cn(
              styles.thumbInnerCircle,
              scrollPosition >= breakpoints.value3 && styles.thumbInnerActive,
            )}
          />
        </div>
      </div>

      <div style={{ top: pointsPosition.fourPoint }} className={styles.thumbWrapper}>
        <div className={cn(styles.thumb, scrollPosition >= breakpoints.value4 && styles.thumbActive)}>
          <div
            className={cn(
              styles.thumbInnerCircle,
              scrollPosition >= breakpoints.value4 && styles.thumbInnerActive,
            )}
          />
        </div>
      </div>
    </div>
  );
};
