import { FC, useEffect } from 'react';

import styles from './index.module.scss';
import { useAppContext } from '../../app/providers/useProvider';
import { MoonIcon, SunIcon } from '../../assets/icons';
import { useMediaQuery } from 'react-responsive';

export const DarkModeToggle: FC = () => {
  const { isDark, setIsDark } = useAppContext();

  const handleChange = () => {
    setIsDark((prevState) => !prevState);
  };

  useMediaQuery(
    {
      query: '(prefers-color-scheme: dark)',
    },
    undefined,
    (isSystemDark) => setIsDark(isSystemDark),
  );

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setIsDark(storedTheme === 'dark');
    } else {
      const isSystemDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setIsDark(isSystemDark);
    }
  }, [setIsDark]);

  useEffect(() => {
    if (isDark) {
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    }
  }, [isDark]);

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={styles.switch}>
      <input type="checkbox" checked={isDark} onChange={handleChange} className={styles.checkbox} />
      <span className={styles.slider}>
        <div className={styles.circle}>{isDark ? <SunIcon /> : <MoonIcon />}</div>
      </span>
    </label>
  );
};
