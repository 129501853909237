import React from 'react';
import { Typography } from '../../components/Typography';
import { ComponentTypes } from '../../types';
import { AppButton } from '../../components/AppButton';
import { FEATURE_LIST } from '../../lib/constants';

import styles from './index.module.scss';
import { ButtonVariant } from '../../app/types';
import { useAppContext } from '../../app/providers/useProvider';
import { FeatureListItem } from './components/FeatureListItem';

export const FeaturesSection = () => {
  const {
    queryState: { isOnlyTablet, isMobile, isDesktop },
  } = useAppContext();

  return (
    <section className={styles.container} id="Features">
      <div className={styles.headline}>
        <Typography
          dataAos="fade-right"
          className={styles.title}
          componentType={ComponentTypes.Title}
          level="h2"
        >
          Features you can get
        </Typography>

        {(isOnlyTablet || isDesktop) && (
          <div data-aos="fade-left">
            <AppButton
              className={styles.button}
              hoverColor="#F7B300"
              activeColor="#F5A700"
              color="#FDCC1B"
              variant={ButtonVariant.PRIMARY}
            >
              <Typography className={styles.buttonText} componentType={ComponentTypes.Link}>
                Get started
              </Typography>
            </AppButton>
          </div>
        )}
      </div>

      <ul className={styles.featuresList}>
        {FEATURE_LIST.map((feature, index) => {
          return <FeatureListItem key={feature.title} feature={feature} index={index} />;
        })}
      </ul>

      {isMobile && (
        <div data-aos="fade-right">
          <AppButton
            className={styles.button}
            hoverColor="#F7B300"
            activeColor="#F5A700"
            color="#FDCC1B"
            variant={ButtonVariant.PRIMARY}
          >
            <Typography className={styles.buttonText} componentType={ComponentTypes.Link}>
              Get started
            </Typography>
          </AppButton>
        </div>
      )}
    </section>
  );
};
